import Vue from 'vue'

import registerModules from './modules'
import registerRuns from './runs'
import registerLearningPaths from './learning-paths'
import registerWaves from './waves'

export default async function register (_: typeof Vue, app: Vue) {
  const subUnregisterFns: (() => void)[] = []

  subUnregisterFns.push(registerModules(_, app))
  subUnregisterFns.push(registerRuns(_, app))
  registerLearningPaths(_, app)
  registerWaves(_, app)

  return () => {
    subUnregisterFns.forEach(fn => fn())
  }
}
